<template>
  <header class="menu-main">
    <div class="logotype">
      <h1 v-if="$route.name === 'index'">
        <a href="/">
          <b style="color:red;">L</b>ALEA
          <!-- <span class="slogan">Ремонт, спорт, красота и здоровье - для него и для нее.</span> -->
        </a>
      </h1>
      <a v-else href="/">
        <b style="color:red;">L</b>ALEA
        <!-- <span class="slogan">Ремонт, спорт, красота и здоровье - для него и для нее.</span> -->
      </a>
    </div>
    <div style="position:relative;height:40px;">
      <div @click="menuScroll('prev')" class="arrow-left"></div>
      <div class="tags">
        <ul v-if="tags.length" :style="`width:${tags.length * (!isDesktop ? 130 : 130)}px`">
          <li>
            <span>#</span>ТЕГИ:
          </li>
          <li
            v-for="tag in tags"
            :key="`tag-${tag.id}`"
            :data-tag-name="tag.name"
            :data-tag-pretty="tag.pretty"
            :class="{ active: articleTag === tag.name || articleTag === tag.pretty }"
          >
            <nuxt-link :to="`/tag/${tag.pretty}`">
              {{ tag.name }}
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div @click="menuScroll('next')" class="arrow-right"></div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['isDesktop', 'tags']),
    changeRoute() {
      return this.$route.fullPath
    },
  },
  watch: {
    changeRoute(val) {
      // console.log('val', val)
      if (val === '/') {
        this.articleTag = ''
        document.querySelector('.menu-main .tags').scroll(0, 0) 
      } else if (val.includes('/tag/')) {
        this.articleTag = val.replace('/tag/', '')
      } else {
        this.markTag()
      }
    },
  },
  data() {
    return {
      articleTag: '',
    }
  },
  mounted() {
    window.addEventListener('scroll', this.stickyTags)

    this.markTag()
  },
  
  methods: {
    markTag() {
      setTimeout(() => {
        const articleTag = document.querySelector('.article header .tags li a')

        if (articleTag) {
          const tagName = articleTag.textContent.replace('#', '').trim()

          const tagElem = document.querySelector(`.menu-main .tags ul li[data-tag-name="${tagName}"]`)

          if (tagElem) {
            document.querySelector('.menu-main .tags').scroll(tagElem.getBoundingClientRect().left - 40, 0) 
          }

          this.articleTag = tagName
        }
      }, 100)
    },
    menuScroll(type) {
      const scrollElem = document.querySelector('.menu-main .tags')
      if (scrollElem) {
        const scrollPosition = scrollElem.scrollLeft
        const scrollWidth = scrollElem.scrollWidth

        if (type === 'next') {
          scrollElem.scroll(scrollPosition + scrollWidth / 2, 0)
        } else {
          scrollElem.scroll(scrollPosition - scrollWidth / 2, 0)
        }
      }
    },
    stickyTags() {
      const docELem = document.documentElement
      const elem = document.querySelector('.menu-main .tags')
      const body = document.querySelector('.site-body')
      if (docELem.scrollTop > (this.isDesktop ? 90 : 50)) {
        elem.classList.add('sticky')
        if (this.isDesktop) {
          body.style.backgroundAttachment = 'fixed'
          body.style.backgroundPosition = '0px 40px'
        }
      } else {
        elem.classList.remove('sticky')
        if (this.isDesktop) {
          body.style.backgroundAttachment = ''
          body.style.backgroundPosition = '0px 150px'
        }
      }
    }
  },
}
</script>

<style src="~/assets/menu.styl" lang="stylus" />

